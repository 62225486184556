<template>
  <div class="about">
    <img class="a_Image" src="@/assets/img/about_banner.png" />
    <ContentTop></ContentTop>
    <!-- <div class="a_content">
            <div class="ac_title">关于米悦</div>
            <div class="ac_content">北京四有青年餐饮创业服务集团成立于 2016年，公司总部位于北京市，现有上海、台肥、石家庄三个运营中心，16个仓储物流中心，运营服务团队超过200人。以“成就每一个餐饮人梦想”为使命，聚集了一群热爱餐饮事业的四有人四有青年通过“品牌孵化+合作经营+总部管理 + 持续服务”的业务模式，成为国内领先的新餐饮创业服务平台。公司拥有项目孵化、供应链、外卖运营品牌营销、门店开设、招商加盟等餐饮全产业链服务能力。</div>
        </div> -->
    <div class="i_bottom">
      <Bottom :hasScroll="hasScroll"></Bottom>
    </div>

  </div>
</template>

<script>
import Bottom from "@/components/bottom";
import ContentTop from "../../components/content_top.vue";
export default {
  name: "aboutIndex",
  components: {
    ContentTop,
    Bottom
  },
  data() {
    return {
      hasScroll: false
    };
  },

  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {
    handleScroll() {
      this.hasScroll = window.pageYOffset > 0;
    }
  }
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .a_Image {
    height: 40rem !important;
  }
}

.about {
  .i_bottom {
    position: absolute;
    bottom: 0;
  }
  img {
    width: 100%;
    height: 50rem;
    background: #eeeeee;
  }
  .a_content {
    margin: 0 auto 2.5rem;
    width: 120rem;
    text-align: center;
    .ac_title {
      font-size: 3.6rem;
      font-weight: 400;
      color: #000000;
      margin: 5rem 0;
    }
    .ac_content {
      text-align: left;
      font-size: 1.6rem;
      font-weight: 400;
      color: #666666;
    }
  }
}
</style>